import React from "react"
import { dateFormatLong } from "../utils/date"


const Post = ({post, thumbnail}) => (
<article key={post.frontmatter.id} className="mb-4 pb-2">
<article className="max-w-xl container mx-auto sm:mx-0 mt-5 p-2 sm:p-0">
<h2 className="font-serif text-4xl mt-2 mb-2 font-black">{post.frontmatter.title}</h2>
<p className="text-gray-500 font-serif text-xl">{dateFormatLong(new Date(post.frontmatter.created))}</p> 
 {thumbnail || <div className="text-justify leading-relaxed text-md markdown text-xl" dangerouslySetInnerHTML={{ __html: post.html }} /> }
</article>
</article>

)

export default Post