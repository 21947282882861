import React from "react"
import Image from 'gatsby-image';

const FeaturedImage = (props) => {
    const image = props.image
return     <div>
    {image?.childCloudinaryAsset?.fluid && <Image fluid={image.childCloudinaryAsset.fluid} alt="banner" />}
    {props.creditLabel && props.displayCredits && (
        <p className="text-right pr-4 text-gray-500">Crédit photo :  {props.creditUri ?
            (<a className="underline" href={props.creditUri}>{props.creditLabel}</a>)
        :
            (props.creditLabel)
        }
        </p>)
    }
</div>
}


export default FeaturedImage
